import React, { useState } from "react";
import SweetAlert2 from "react-sweetalert2";

function InfoModal() {
  let [open, setOpen] = useState(!localStorage.getItem("infomodal:closed"));
  let alert = {};
  if (open) {
    alert = {
      show: true,
      title: "Infinite Alchemy",
      confirmButtonText: "Play",
      html: `Inspired by <a href="https://littlealchemy2.com/">Little Alchemy</a>,
      Infinite Alchemy is a game where you combine elements to create more
      complex ones. Drag and drop elements onto each other to combine them.
      The goal of the game is up to you, but you can try to create as many
      unique elements as possible or pick a thing and try to craft it (e.g.
      flowers). The twist is that the elements and the images are dynamically
      generated by AI (GPT, DALLE), hence "infinite". Mixtures are used up anytime you
      combine a unique never-tried-before pair of elements and can be purchased or won
      in daily challeges.`,
    };
  }
  return (
    <div>
      <SweetAlert2
        {...alert}
        onConfirm={() => {
          setOpen(false);
          localStorage.setItem("infomodal:closed", true);
        }}
      ></SweetAlert2>
    </div>
  );
}

export default InfoModal;
